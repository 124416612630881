import styled from 'styled-components';
import { Link } from 'gatsby';

export const Title = styled.div`
  text-align: center;
  margin-top: 70px;

  svg {
    width: 80px;
    fill: #faab06;
  }

  h3 {
    font-family: Muli;
    font-size: 72px;
    line-height: 1;
    font-weight: 700;
    color: #faab06;
  }

  .total-recipes {
    color: #bbb;
    font-size: 11px;
    text-transform: uppercase;
    margin-top: 10px;
    font-weight: 600;
  }
`;

export const RecipeLink = styled(Link)`
  color: #333;
  text-decoration: none;
  border: 1px #ddd solid;
  padding: 25px;
  border-radius: 5px;
  margin-bottom: 30px;
  position: relative;
  overflow: hidden;
  display: block;

  .recipe-img-container {
    border: 2px #eee solid;
    border-radius: 250px;
    display: inline-block;
    margin-bottom: 20px;
  }

  .placeholder {
    width: 200px;
    height: 200px;
    border-radius: 250px;
    border: 5px #fff dashed;
    padding: 8px;
  }

  &:hover {
    border-color: #faab06;
    box-shadow: 0 4px 0 rgba(250, 171, 6, .1);

    h2 {
      color: #faab06;
    }

    .recipe-img-container {
      border-color: #faab06;
    }
  }
`;

export const TryRecipe = styled.div`
  font-family: Caveat;
  text-align: center;
  font-size: 28px;
  margin-bottom: 25px;
  margin-top: 30px;

  .emoji {
    display: inline-block;
    margin: 0 0 0 10px;
    vertical-align: middle;
  }
`;

export const RecipeCover = styled.div`
  width: 200px;
  height: 200px;
  background-image: url(${props => props.photo});
  background-size: cover;
  background-position: center;
  border-radius: 250px;
  border: 5px #fff solid;
  padding: 8px;
`;

export const RecipeContainer = styled.div`
  h2 {
    margin: 0 0 10px;
  }

  .date {
    font-size: 13px;
    color: #bbb;
  }

  .excerpt {
    color: #52616a;
    margin-top: 30px;
  }
`;

export const Tags = styled.div`
  margin-top: 15px;

  span {
    font-size: 11px;
    text-transform: uppercase;
    background: #52616a;
    display: inline-block;
    color: #fff;
    font-weight: 600;
    padding: 5px 8px;
    border-radius: 3px;
    margin-right: 5px;
    line-height: 1;
  }
`;

export const SideBar = styled.div`
  padding: 0 30px;

  .about {
    img {
      border-radius: 500px;
      border: 3px #ddd solid;
      padding: 8px;
    }

    .content {
      color: #77919d;
      font-size: 16px;
    }
  }
`;

export const TestimonyContainer = styled.div`
  padding: 35px 20px;
  border: 1px #ddd solid;
  margin: 30px;
  border-radius: 6px;
  box-shadow: 0 3px 0 #f9f9f9;
`;
