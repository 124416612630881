/* eslint-disable import/first */
typeof window !== 'undefined' && require('intersection-observer');

import React from 'react';
import { graphql } from 'gatsby';
import { Grid, Row, Col } from 'react-flexbox-grid';
import Confetti from 'react-confetti';
import { LazyImage } from 'react-lazy-images';
import moment from 'moment';
import 'moment/locale/pt';

import Photo from '../images/Marta.jpg';
import testimonies from '../data/testimonies.json';

import Layout from '../components/Layout';
import Header from '../components/Header';
import Breaker from '../components/Breaker';
import Testimony from '../components/Testimony';
import { RECIPES_PATH } from '../constants/Routes';
import SEO from '../components/Seo';
import {
  Title,
  RecipeLink,
  TryRecipe,
  RecipeCover,
  RecipeContainer,
  Tags,
  SideBar,
  TestimonyContainer
} from '../styles/recipes';

import WokIcon from './icons/Wok.svg';

const confettiConfig = {
  width: 600,
  height: 300,
  numberOfPieces: 200,
  recycle: false,
  opacity: .5
}

const Receitas = ({ data, location: { hash, search } }) => {
  if (hash) {
    const id = hash.replace('#', '');

    setTimeout(() => {
      const element = document.getElementById(id);
      if (element) element.scrollIntoView({
        inline: "nearest"
      });
    }, 100);
  }

  const recipeInHash = (slug) => {
    const cleanSlug = slug.split('/')[2]

    return hash && cleanSlug === hash.replace('#', '') && !search
  }

  // Get random testemony
  const testimony = testimonies[Math.floor(Math.random() * testimonies.length)]

  return (
    <Layout>
      <SEO title="Receitas" keywords={[`paleo`, `health`, `wellness`, `coach`, `fitness`, `coaching`, `chef`, `mindfulness`, `desporto`, `gym`, `ginasio`, `receitas`, `pratos`, `bolos`, `receita`, `snack`, `lanche`]} />
        <Header />

        <Grid>
          <Row>
            <Col md={12}>
              <Title>
                <WokIcon />
                <h3>Receitas</h3>
                <div className="total-recipes">
                  {data.recipes.totalCount} receitas
                </div>
              </Title>
            </Col>
          </Row>

          <Breaker />

          <Row>
            <Col md={8}>
              {data && data.recipes.edges.map(({ node }) => (
                <Row key={node.id}>
                  <Col md={12}>
                    {recipeInHash(node.fields.slug) && <Confetti {...confettiConfig} />}

                    <RecipeLink
                      id={node.fields.slug.split('/')[2]}
                      to={`${RECIPES_PATH}${node.fields.slug}`}
                      className={recipeInHash(node.fields.slug) && `check-this-recipe`}
                    >
                      {recipeInHash(node.fields.slug) &&
                        <TryRecipe>
                          <span className="text-highlight">
                            Experimenta esta receita deliciosa que eu encontrei
                            <span className="emoji" role="img" aria-label="emoji">
                              🥰
                            </span>
                          </span>
                        </TryRecipe>
                      }

                      <Row middle="md">
                        <Col md={4}>
                          <div className="recipe-img-container">
                            <LazyImage
                              src={node.frontmatter.cover}
                              alt={node.frontmatter.title}
                              placeholder={({ imageProps, ref }) => <div className="placeholder" ref={ref} />}
                              actual={({ imageProps }) => <RecipeCover photo={imageProps.src} />}
                            />
                          </div>
                        </Col>

                        <Col md={8}>
                          <RecipeContainer>
                            <h2>{node.frontmatter.title}</h2>
                            <div className="date">
                              {moment(node.frontmatter.date).format('D MMMM YYYY')}
                            </div>

                            <div className="excerpt">{node.excerpt}</div>
                          </RecipeContainer>

                          <Row>
                            <Col md={8}>
                              <Tags>
                                {node.frontmatter.tags.map((tag, i) => <span key={i}>{tag}</span>)}
                              </Tags>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </RecipeLink>
                  </Col>
                </Row>
              ))}
            </Col>

            <Col md={4}>
              <SideBar>
                <div className="about">
                  <img src={Photo} alt="Marta Simões" />

                  <h3>Na Caverna da Marta</h3>

                  <div className="content">
                    Ora nesta caverna habitam 5 pessoas! Uma mulher das cavernas (eu),
                    um homem que é meia casa (o noivo Tiago) e 3 pestinhas que são metade/metade!
                    Vivemos numa pequena cidade, Cartaxo, numa casa com quintal para
                    correr e um jardim perto. Tenho uma cozinha mais ou menos grande,
                    onde passo grande parte do tempo!

                    <br /><br />

                    Fui atirada para o desemprego, como uma grande parte dos Portugueses...
                    mas baixar os braços não é de uma mulher das cavernas. E por isso fui
                    à luta! Quero tirar formação em nutrição... aproveitar o tempo livre
                    para ler muito, para fazer muitas experiências e quem sabe se o meu
                    futuro não passa por aquilo que me faz feliz.
                  </div>
                </div>
              </SideBar>

              <Breaker />

              <TestimonyContainer>
                <Testimony author={testimony} />
              </TestimonyContainer>
            </Col>
          </Row>
        </Grid>
    </Layout>
  )
}

export const query = graphql`
  query {
    recipes: allMarkdownRemark(
      filter: { fields: { collection: { eq: "recipes" } } },
      sort: { order: DESC, fields: [frontmatter___date]}
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date
            tags
            cover
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`

export default Receitas
